// Libraries

import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { get } from "lodash"

// Components

import SEO from "../components/global/SEO"
import PageLayout from "../components/sharedPageLayout"
import ReactHtmlParser from "react-html-parser"

//helpers
import { mobileSize, desktopSize } from "../helpers/sizeCalculations"

// Styles

const Page = styled.div`
  .history-subtitle {
    padding: ${desktopSize(375)} 0 ${desktopSize(270)};
    @media screen and (max-width: 768px) {
      padding: ${mobileSize(290)} 0 ${mobileSize(260)};
    }
  }
`

const HistoryContent = styled.div`
  @media screen and (max-width: 768px) {
    padding-right: 27px;
  }
`

/*-----------------------------
Styles
-----------------------------*/

/*-----------------------------
Component
-----------------------------*/

class HistoryPage extends React.Component {
  constructor(props) {
    super(props)
    // Refs
    // State
    this.state = {}
  }

  componentDidMount() {}

  /*-----------------------------
  Render
  -----------------------------*/

  render() {
    //Assigning the props value to a variable. So that we avoid writing repeating node the get the adequate props value.
    const historyData = this.props.data.allWordpressPage.nodes[0]

    const pageLayoutProps = {
      ...this.props,
      title: get(historyData, "title"),
      subTitle: get(historyData, "custom.history_sub_title"),
      content: get(historyData, "custom.history_content"),
      image: get(historyData, "custom.history_images"),
      headerClassName: "history-subtitle",
      hideImageInResponsive: false,
      responsiveDividerYOffset: 290,
      showBackButton: true,
    }

    return (
      <Page>
        <SEO title={pageLayoutProps.title} />
        <PageLayout {...pageLayoutProps}>
          <HistoryContent className="ipad">
            {ReactHtmlParser(pageLayoutProps.content)}
          </HistoryContent>
        </PageLayout>
      </Page>
    )
  }
}

export default HistoryPage

/*------------------------------
Graphql Queries
------------------------------*/

export const historyQuery = graphql`
  query historyPageQuery {
    allWordpressPage(filter: { slug: { eq: "larry-ellisons-story" } }) {
      nodes {
        title
        custom {
          history_sub_title
          history_content
          history_images {
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt_text
            }
          }
        }
      }
    }
  }
`
